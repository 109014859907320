// import React, { useState } from "react";
// import axios from "axios";
// import AnsLogo from "./Assets/AnsLogo..png";

// interface DisplayedData {
//   asin: string;
//   estimatedSales: string;
// }

// const Fba: React.FC = () => {
//   const [inputValue, setInputValue] = useState<string>("");
//   const [displayedData, setDisplayedData] = useState<(DisplayedData | null)[]>(
//     []
//   );
//   const [selectedOption, setSelectedOption] = useState<string>("");

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setInputValue(e.target.value);
//   };

//   const handleButtonClick = async () => {
//     if (!inputValue.trim()) {
//       console.log("Please enter ASIN.");
//       return;
//     }
//     try {
//       const asins = inputValue.split(",").map((asin) => asin.trim());

//       const displayedDataArray: DisplayedData[] = [];

//       for (const asin of asins) {
//         const response = await axios.post(
//           "https://salesestimatorapi.agilensmartservices.com/SalesEstimate/Estimate",
//           {
//             identifiers: asin,
//           }
//         );
//         displayedDataArray.push(response.data[0]);
//       }

//       setDisplayedData(displayedDataArray);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setSelectedOption(e.target.value);
//   };

//   return (
//     <div className="container mx-auto px-4 mt-10 ml-10">
//       <div className="flex justify-center items-center">
//         <img src={AnsLogo} className="max-w-1/8 h-20" />
//       </div>

//       <div className="flex justify-center items-center h-screen">
//         {" "}
//         <div className="w-full md:w-1/2">
//           <div className="p-0">
//             <div>
//               <h1
//                 className="text-4xl md:text-5xl font-bold mb-4 leading-relaxed"
//                 style={{ lineHeight: "1.5" }}
//               >
//                 Sales Estimator Calculator: Estimate Sales Estimator Seller Fees
//                 Revenue, & Profit
//               </h1>
//             </div>

//             <div>
//               <h6 className="text-lg mb-2 text-[#91A2B1]">
//                 Find profitable products with this Sales Estimator Calculator
//               </h6>
//             </div>
//             <div>
//               <p className="text-2xl mb-3">
//                 Use our bulk Sales Estimator Calculator to learn about the
//                 potential fees and revenue of any product.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-1/5  shadow-md rounded-md p-4 bg-[#f9a31c] flex flex-col justify-center items-center">
//           <div className="mt-20 w-full">
//             <div className="flex justify-center mb-4">
//               <input
//                 type="text"
//                 value={inputValue}
//                 onChange={handleInputChange}
//                 placeholder="Enter ASIN"
//                 className="w-full h-10 px-3 rounded-md border border-gray-300 focus:outline-none focus:border-white"
//               />
//             </div>

//             <button
//               onClick={handleButtonClick}
//               className="w-full h-12 px-4 font-bold bg-white text-dark rounded-md hover:bg-black hover:text-white focus:outline-none focus:bg-white focus:text-black"
//             >
//               Get Sale
//             </button>
//           </div>
//           <br />
//           {displayedData.length > 0 && (
//             <div className="bg-white shadow-md rounded-md p-4 mb-4">
//               {displayedData.map((data, index) => (
//                 <div key={index}>
//                   <p className="text-lg font-semibold">
//                     Estimated Monthly Sales: {data?.estimatedSales}
//                   </p>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Fba;

import React, { useState } from "react";
import axios from "axios";
import AnsLogo from "./Assets/AnsLogo..png";

interface DisplayedData {
  asin: string;
  estimatedSales: string;
}

const Fba: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [displayedData, setDisplayedData] = useState<(DisplayedData | null)[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = async () => {
    if (!inputValue.trim()) {
      console.log("Please enter ASIN.");
      return;
    }
    try {
      const asins = inputValue.split(",").map((asin) => asin.trim());
      const displayedDataArray: DisplayedData[] = [];
      for (const asin of asins) {
        const response = await axios.post(
          "https://salesestimatorapi.agilensmartservices.com/SalesEstimate/Estimate",
          {
            identifiers: asin,
          }
        );
        displayedDataArray.push(response.data[0]);
      }
      setDisplayedData(displayedDataArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {" "}
      <div className="flex justify-center items-center mt-4">
        <img src={AnsLogo} className="max-w-1/8 h-20" alt="AnsLogo" />
      </div>
      <div className="container mx-auto px-4 mt-60">
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="md:w-1/2 md:mr-4">
            <div className="p-0">
              <div>
                <h1
                  className="text-4xl md:text-5xl font-bold mb-4 leading-relaxed text-left"
                  style={{ lineHeight: "1.5" }}
                >
                  Sales Estimator
                </h1>
                <h1 className="text-2xl text-left">
                  Unlock Your Sales Potential with Our Sales Estimator App!
                </h1>
              </div>
              <br />
              <div>
                <h6 className="text-lg mb-2 text-[#91A2B1] text-left">
                  Streamline your decision-making process and maximize profits
                  with accurate forecasts at your fingertips. Get started today
                  and revolutionize your sales strategy!
                </h6>
              </div>
            </div>
          </div>
          <div className="md:w-1/4 md:ml-4 mt-[2rem]">
            <div className="shadow-md rounded-md p-4 bg-[#f9a31c] flex flex-col justify-center items-center">
              <div className="mt-8 w-full">
                <div className="flex justify-center mb-4">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter ASIN"
                    className="w-full h-10 px-3 rounded-md border border-gray-300 focus:outline-none focus:border-white"
                  />
                </div>
                <button
                  onClick={handleButtonClick}
                  className="w-full h-12 px-4 font-bold bg-black  text-white rounded-md  focus:outline-none  mb-4"
                >
                  Get Sales Estimate
                </button>
                {displayedData.length > 0 && (
                  <div className="bg-white shadow-md rounded-md p-4">
                    {displayedData.map((data, index) => (
                      <div key={index}>
                        {data ? (
                          <p className="text-lg font-semibold">
                            Estimated Monthly Sales: {data.estimatedSales}
                          </p>
                        ) : (
                          <p className="text-lg font-semibold text-red-500">
                            Error fetching data for this ASIN
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fba;
